<template>
  <div class="orizzontale-form flex-col gap-20">
    <div class="ai-start jc-between">
      <div class="puntuale-title">
        <VIcon :src="require('@/assets/icons/form.svg')" />
        <h2>Nuovo monitoraggio<br />orizzontale</h2>
      </div>
      <VButton :icon="require('@/assets/icons/cross.svg')" @click="close" />
    </div>
    <form v-if="!loading" class="form-box-orizzontale grid" @submit.prevent="submit">
      <div class="wrapper flex-col ai-start">
        <div class="block top"></div>
        <div class="grid grid-2-col gap-10 bg-primary-color">
          <InputWithLabel class="medium ai-center grid-col-span" label="Volo di riferimento:" type="select"
            v-model="formData.drone_flight_id" :options="drone_flights" />
          <InputWithLabel class="ai-center grid-col-span disabled rounded" label="Data analisi:" disabled />
          <InputWithLabel class="medium ai-center grid-col-span" label="Progetto di riferimento:" type="select"
            :options="projects" v-model="formData.project_id" :createUrl="'/api/project'" />
          <InputWithLabel class="medium ai-center grid-col-span" label="Problematica:" type="select"
            :options="horizontal_degradation_types" v-model="formData.horizontal_degradation_type_id" />
          <InputWithLabel class="medium ai-center grid-col-span" label="Intensità:" type="select"
            :options="horizontal_degradation_intensities" v-model="formData.horizontal_degradation_intensity_id" />
          <InputWithLabel class="grid-col-span flex-col ai-start" label="Osservazioni:" type="textarea"
            v-model="formData.observations" />
          <InputWithLabel class="medium ai-center grid-col-span rounded" label="Segnalatore:" type="text" disabled
            v-model="authStore.user.full_name" />
        </div>
        <VLottie class="loading" v-if="loading" ref="animation" :src="require('@/assets/icons/loading.json')" autoplay
          loop />
        <div class="block top"></div>
      </div>
      <div class="flex-col bg-primary-color jc-between gap-20">
        <div class="flex-col">
          <VMap class="ref-top-map-puntuale" :polygons="formData.polygon ? [formData.polygon] : []" v-if="!loading"
            :customLayers="MAP_LAYERS" :drawing="true" @draw:created="addPolygon" @draw:updated="updatePolygon"
            :center="MAP_CENTER" :currentLayer="1" @draw:removed="removePolygon" />
          <div class="ref-top-details flex-col gap-5">
            <p><strong>REGIONE: </strong>{{ topographic_element_4?.environment?.unity.insula.region.name || '-' }}</p>
            <p><strong>INSULA: </strong>{{ topographic_element_4?.environment?.unity.insula.name || '-' }}</p>
            <p><strong>UNITÀ: </strong>{{ topographic_element_4?.environment?.unity.name || '-' }}</p>
            <p><strong>AMBIENTE: </strong>{{ topographic_element_4?.environment?.name || '-' }}</p>
          </div>
        </div>
        <div class="flex-col gap-10">
          <div class="buttons gap-10 grid grid-2-col">
            <VButton class="rounded" label="Cancella" @click="close" />
            <VButton type="submit" class="rounded" label="Salva" />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script setup>
import InputWithLabel from '../InputWithLabel.vue';

import { useAuthStore } from '@/stores/auth';
import { useRouter } from 'vue-router';
import { ref, defineEmits, onMounted, reactive } from 'vue'
import getTable from '@/services/composables/getTable';
import useMonitoring from '@/services/composables/useMonitoring';
import { useToast } from 'vue-toastification';
import { axiosInstanceWithAuth } from '@/utils/utils'
import { MAP_CENTER, MAP_LAYERS } from '@/utils/polygon';


const router = useRouter();
const authStore = useAuthStore();
const loading = ref(true);
const topographic_element_4 = ref(null);
const { values: projects, load: loadProjects } = getTable('project', 'projects')
const { values: horizontal_degradation_types, load: loadHorizontalDegradationTypes } = getTable('horizontal-degradation-type', 'horizontalDegradationTypes')
const { values: horizontal_degradation_intensities, load: loadHorizontalDegradationIntensities } = getTable('horizontal-degradation-intensity', 'horizontalDegradationIntensities')
const { values: drone_flights, load: loadDroneFlights } = getTable('drone-flight', 'droneFlights')
const toast = useToast();

defineEmits(['submit'])
const { addMonitoring } = useMonitoring('horizontal-monitoring')

const formData = reactive({
  project_id: null,
  drone_flight_id: null,
  horizontal_degradation_type_id: null,
  horizontal_degradation_intensity_id: null,
  observations: null,
  polygon: {
    type: 'FeatureCollection',
    features: []
  }
})

const addPolygon = (geojson) => {
  formData.polygon = JSON.parse(formData.polygon)
  formData.polygon.features.push(geojson)
  formData.polygon = JSON.stringify(formData.polygon)
}

const updatePolygon = (geojson) => {
  formData.polygon = JSON.parse(formData.polygon)
  formData.polygon.features = formData.polygon.features.map((feature) => {
    if (feature.properties.id === geojson.properties.id) return geojson
    else return feature
  })
  formData.polygon = JSON.stringify(formData.polygon)
}

const removePolygon = (id) => {
  formData.polygon = JSON.parse(formData.polygon)
  formData.polygon.features = formData.polygon.features.filter((feature) => feature.properties.id !== id)
  formData.polygon = JSON.stringify(formData.polygon)
}

const submit = () => {
  // add id if present in route
  if (router.currentRoute.value.params.id) {
    formData.id = router.currentRoute.value.params.id
  }

  // add monitoringId if present in route
  if (router.currentRoute.value.params.monitoringId) {
    formData.monitoring_id = router.currentRoute.value.params.monitoringId
  }

  // stringify the polygon if it's an object or array
  if (typeof formData.polygon === 'object' || Array.isArray(formData.polygon)) {
    formData.polygon = JSON.stringify(formData.polygon)
  }

  addMonitoring(formData, false)
    .then(() => {
      close()
    })
    .catch((err) => {
      toast.error('Errore durante il salvataggio del monitoraggio: ' + err.message, {
        position: 'bottom-center'
      })
    })
}

const close = () => {
  if (router.currentRoute.value.params.id) {
    router.push({ name: 'mostra-monitoraggio-orizzontale', params: { id: router.currentRoute.value.params.id } })
  } else {
    router.push({ path: router.currentRoute.value.meta.onClosePath })
  }
}

onMounted(async () => {
  await loadProjects()
  await loadHorizontalDegradationTypes()
  await loadHorizontalDegradationIntensities()
  await loadDroneFlights()

  // load monitoring if id is present in route
  if (router.currentRoute.value.params.id) {
    let res = await axiosInstanceWithAuth.get('/api/horizontal-monitoring/' + router.currentRoute.value.params.id)
    formData.project_id = res.data.project_id
    formData.drone_flight_id = res.data.drone_flight_id
    formData.observations = res.data.observations
    formData.horizontal_degradation_type_id = res.data.horizontal_degradation_type_id
    formData.horizontal_degradation_intensity_id = res.data.horizontal_degradation_intensity_id
    formData.polygon = res.data.polygon
  }

  loading.value = false
})

</script>
<style scoped>
.orizzontale-form {
  position: absolute;
  z-index: 4;
  height: 100%;
  width: 100%;
  background-color: var(--base-light-color);
  padding: 40px 40px 40px 60px;
  font-size: .875rem;
}

.form-box-orizzontale {
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  height: 100%;
  overflow: hidden;
}

.form-box-orizzontale>div:last-child>div:first-child {
  height: 100%;
}

.form-box-orizzontale .wrapper>div {
  width: 100%;
}
</style>